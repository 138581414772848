var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "hb-movie-thumbnail",
      style: {
        backgroundImage: _vm.backgroundImage
      },
      on: {
        click: function() {
          return _vm.open()
        }
      }
    },
    [
      _c("div", { staticClass: "movie-thumbnail--icon" }, [
        _c("img", {
          attrs: { src: _vm.$lib.imgUrl("img/movie/play.png"), alt: "play" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }