<template>
  <div class="offer-list-box" :style="{ margin: data.length === 1 ? '0 auto' : null }">
    <div class="offer-list" :style="{ width: `${data.length * 45 + 2}rem` }">
      <o-main-item
        v-for="(row, key) in data"
        :row="row"
        :key="key"
        :map="map"
        :time="time"
        :money="money"
        :footer="footer"
        :myOffer="myOffer"
        />
    </div>
  </div>
</template>

<script>
import OMainItem from '@/components/offer/OMainItem'

export default {
  components: {
    OMainItem,
  },
  props: {
    data: {
       type: Array,
       default: () => [],
    },
    map: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Boolean,
      default: false,
    },
    money: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    myOffer: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.offer-list-box {
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 -3rem;
  // ios 스크롤 관련
  -webkit-overflow-scrolling: touch;

  .offer-list {
    overflow: hidden;
  }
}
</style>
