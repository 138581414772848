<template>
    <div class="main-page-container container home" :class="isClient ? 'client' : ''" ref="container">
        <header
            :style="{
                // marginTop: isClient ? '0' : '40px', 전문가 header 상단 영역 수정
                paddingBottom: '0rem',
            }"
        >
            <div class="header-top">
                <h1 class="logo">
                    <img src="img/logo-b@3x.png" alt="헤이비글" />
                </h1>
            </div>
            <div class="customers">
                <div class="rolling-list-wrap">
                    <ul class="rolling-list" ref="rollingList">
                        <li v-for="items in rollingBanner" :key="items.id"><span class="customers-logo"><img :src="items.image" :alt="items.alt"></span></li>
                    </ul>
                </div>
                <span class="customers-txt"
                    >임직원들도 헤이비글과 함께했어요.</span
                >
            </div>
            <page-header
                :title="
                    isClient
                        ? `완벽한 행사,
                        10분 만에 준비하세요`
                        : !hasOfferForTomorrow
                        ? `안녕하세요`
                        : `${$store.state.user.userData.userName}님,`
                "
                :subtitle="isClient ? `` : ``"
                :category="userState.userData.category"
                :titleForArtist="
                    (hasOfferForTomorrow &&
                        `내일 행사가 있으시네요.\n잘 준비하세요!`) ||
                        ''
                "
            >
            </page-header>
        </header>

        <div class="main-avg-data">
            <ul v-if="isArtist">
                <li>
                    누적 의뢰
                    <b>{{ $lib.addComma(mainAvgData.offerTotalCount) }}</b
                    >건
                </li>
                <li>
                    등록금액
                    <b>{{ $lib.moneyFormat(mainAvgData.offerMaxPriceSum) }}</b
                    >원
                </li>
            </ul>
        </div>

        <template v-if="isArtist">
            <div
                class="ui-border-line ui-h-1"
                style="height: 1px; background-color: #e4e4e4"
            ></div>
            <div class="profile-tooltip ui-mt-0"  v-if="!showProfile">
                <h3>
                    <span>프로필</span>
                    <icon
                        class="profile-tooltip-btn float-right ui-btn"
                        src="img/common/info_circle.png"
                        width="2.2rem"
                        height="9rem"
                        :cover="false"
                        @click="() => showTooltip('profile')"
                    />
                    <div
                        class="profile-tooltip-toast toasted primary default"
                        :class="{ show: tooltip.profile }"
                    >
                        실제 의뢰인들이 보는 프로필 모습이예요.<br />더
                        매력적이고 멋지게 관리해보세요.
                    </div>
                </h3>

                <img
                    class="artist-profile ui-btn ui-mb-3"
                    @click="$router.push('/artist/my-profile')"
                    :src="$lib.imgUrl('img/home/no-profile.png')"
                    width="100%"
                />
                <!-- <div
          v-if="!showProfile"
          class="artist-profile ui-btn"
          @click="$router.push('/artist/my-profile')"
          :style="{
            width: `${$store.state.app.width * 0.88}px`,
          }"
          >
          <icon
            src="img/illustration/invalid-name.png"
            :width="$store.state.app.width >= 540 ? '15rem' : '6rem'"
            :height="$store.state.app.width >= 540 ? '15rem' : '11rem'"
            :cover="false"
            />
          <h5
            :style="{
              marginLeft: $store.state.app.width >= 540 ? null : '2.2rem',
              fontSize: $store.state.app.width >= 540 ? null : '2rem',
            }"
            >{{'Oops!\n프로필이 없으시네요.'}}</h5>
          <p
            :style="{
              marginLeft: $store.state.app.width >= 540 ? null : '2.2rem',
              fontSize: $store.state.app.width >= 540 ? null : '1.5rem',
            }"
            >{{'의뢰를 받고 싶으세요?\n지금 바로 등록하세요!'}}</p>
        </div> -->
                <!-- <template v-else>
                    <div class="d-flex"> -->
                        <!-- 멤버십 무료 전환 - 뱃지 미노출 주석 처리 -->
                        <!-- <template v-if="isNewMembershipUser">
                            <badge type="light">플러스</badge>
                        </template> -->
                        <!-- <template v-if="hasEventMemo">
                            <badge type="light">이벤트</badge>
                        </template>
                        <template v-if="isEarlybirdEventArtist">
                            <badge type="light" :style="{ width: '10rem' }"
                                >24시간 할인</badge
                            >
                        </template>
                    </div>
                    <a-hot-item
                        v-if="artistData.memberYN === 'y'"
                        :row="artistData"
                        class="ui-mt-0"
                    />
                    <a-item v-else :row="artistData" class="ui-mt-0" />
                </template> -->
            </div>

            <div class="ui-border-line" style="height: 10px"></div>
        </template>

        <!-- 최근 등록(지원)한 의뢰 -->
        <!-- 2019-03-18 사용안함 -->
        <!-- <template v-if="recentOfferData.length">
      <div>
        <h3>
          최근 {{isClient ? '등록한' : '지원한'}} 의뢰
          <button class="btn-more" @click="$router.push(`/${userState.userData.category}/my-offer`)">더 보기</button>
        </h3>

        <o-main-list
          :data="recentOfferData"
          :map="!isClient"
        />
      </div>

      <div class="ui-border-line" style="height: 10px"></div>
    </template> -->

        <!-- 최근 섭외(결제)된 의뢰 목록 -->
        <template v-if="isArtist && offerProData.length">
            <div>
                <h3>섭외된 의뢰</h3>
                <p
                    :style="{
                        color: '#848484',
                        fontSize: '1.8rem',
                        fontWeight: '400',
                        marginTop: '-1em',
                        marginBottom: '1em',
                    }"
                >
                    가까운 행사부터 보여드릴게요. 잊지말고 챙기세요!
                </p>
                <o-main-list
                    :data="offerProData"
                    :map="false"
                    :time="true"
                    :money="false"
                    :footer="false"
                    :myOffer="false"
                />
            </div>

            <div class="ui-border-line" style="height: 10px"></div>
        </template>

        <template v-if="isArtist">
            <div>
                <h3>전체 의뢰</h3>

                <div class="ui-border-line ui-mb-3"></div>

                <ul>
                    <template v-for="(row, key) in offerData">
                        <o-item :row="row" :is-simple="true" :key="key" />
                        <div
                            class="ui-border-line ui-mt-3 ui-mb-3"
                            :key="'line' + key"
                        ></div>
                    </template>
                </ul>
            </div>
        </template>

        <template v-if="isClient">
            <!-- 아이콘 -->
            <genre-icon-list
                @click="
                    (genre) =>{
                        if(genre=='연예인'){
                            $router.push({ path: '/artist/celebrity', query: { genre } })
                        } else{
                            $router.push({ path: '/artist/list', query: { genre } })
                        }
                    }
                "
                no-highlight
            />

            <template v-if="mainMovData.length > 0">
                <h3>이런 공연은 어떠세요?</h3>
                <a-mov-list
                    :data="mainMovData"
                    :style="{
                        margin: '0 -3rem',
                        padding: '0 3rem',
                    }"
                />
                <div
                    class="ui-border-line ui-mt-2 ui-mb-1 ui-h-1"
                    style="height: 10px"
                ></div>
            </template>

            <!-- <template v-if="pickArtistData.length > 0">
        <h3>다른 분들의 Pick</h3>
        <a-mini-list
          :data="pickArtistData"
          :style="{
            margin: '0 -3rem',
            padding: '0 3rem',
          }"
          />
        <div class="ui-border-line ui-mt-3 ui-mb-1 ui-h-1" style="height: 10px"></div>
      </template> -->

            <!-- 최근 1:1오퍼 많이 받은 전문가 -->
            <template v-if="directOfferArtistData.length > 0">
                <div
                    data-v-fae5bece=""
                    class="ui-border-line ui-mt-3 ui-mb-1 ui-h-1"
                    style="height: 10px;"
                ></div>
                <h3>이번 달 인기 전문가</h3>
                <a-mini-list
                    :data="directOfferArtistData"
                    :style="{
                        margin: '0 -3rem',
                        padding: '0 3rem',
                    }"
                />
                <div
                    class="ui-border-line ui-mt-2 ui-mb-1 ui-h-1"
                    style="height: 10px"
                ></div>
            </template>

            <div class="home--no-show" @click="$router.push('/etc/no-show')">
                <img
                    src="img/home/no-show-banner.png"
                    alt="노쇼, 사기거래 Zero 어디에도 없는 300% 보상제도"
                />
            </div>

            <div
                data-v-fae5bece=""
                class="ui-border-line ui-mt-3 ui-mb-1 ui-h-1"
                style="height: 10px;"
            ></div>

            <template v-if="mainMenuData.length">
                <template v-for="(row, key) in mainMenuData">
                    <div :key="key">
                        <router-link class="text-decoration-none" :to="{   
                                name: 'ClientMainMenuGroupArtistList',
                                params: {
                                    mainMenuGroupUid: row.mainMenuGroupUid,
                                },
                            }"
                        >
                            <h3 class="home-title">
                                <span class="home-title--text">{{
                                    row.contents
                                }}</span>
                                <span
                                    class="btn-more text-decoration-none"
                                >
                                    <span class="sr-only">전체 보기</span>
                                </span>
                            </h3>
                        </router-link>
                    

                        <!-- <template
                            v-if="
                                row.artistData &&
                                    row.artistData[0] &&
                                    row.artistData[0].movUrl
                            "
                        >
                            <a-mov-list
                                :data="
                                    row.artistData.map((item) => ({
                                        artistidx: item.artistidx,
                                        genre: item.artistGenre,
                                        movUrl: item.movUrl,
                                        teamImgUrl: item.teamImgUrl,
                                        teamName: item.teamName,
                                        mainMenuArtistUid:
                                            item.mainMenuArtistUid,
                                    }))
                                "
                                :style="{
                                    margin: '0 -3rem',
                                    padding: '0 3rem',
                                }"
                            />
                        </template>
                        <template v-else> -->
                        <template>
                            <a-mini-list
                                :data="row.artistData"
                                :style="{
                                    margin: '0 -3rem',
                                    padding: '0 3rem',
                                }"
                            >
                                <template v-slot="{ item }">
                                    <a-mini-item
                                        :imgUrl="item.teamImgUrl"
                                        :genre="item.artistGenre || ''"
                                        :name="item.teamName || ''"
                                        :artistidx="item.artistidx"
                                        :mainMenuArtistUid="
                                            item.mainMenuArtistUid
                                        "
                                        :hashTag="item.hashTag"
                                        :reviewCount="item.reviewCount"
                                        :favCount="item.favCount"
                                        :pointCount="item.pointCount"
                                    />
                                </template>
                            </a-mini-list>
                        </template>

                        <div
                            class="ui-border-line ui-mt-2 ui-mb-1 ui-h-1"
                            style="height: 10px"
                        ></div>
                    </div>
                </template>
            </template>

            <!-- 최근 1:1오퍼 많이 받은 전문가 -->
            <!-- <template v-if="offerPayArtistData.length > 0">
        <h3>최근 섭외된 분들</h3>
        <a-mini-list
          :data="offerPayArtistData"
          :style="{
            margin: '0 -3rem',
            padding: '0 3rem',
          }"
          />
        <div class="ui-border-line ui-mt-3 ui-mb-1 ui-h-1"></div>
      </template> -->

            <!-- 아티스트 키워드 -->
            <!-- <template v-if="artistKeywordData.length > 0">
        <h3>추천</h3>
        <tag-box>
          <li
            v-for="(row, key) in artistKeywordData"
            :key="key"
            @click="() => searchArtistByAdminMemo(row.contents)"
          >{{row.contents}}</li>
        </tag-box>
        <div class="ui-border-line ui-mt-2 ui-mb-1 ui-h-1" style="height: 10px"></div>
      </template> -->

            <!-- 추천 -->
            <div>
                <h3>기획전</h3>
                <ul class="recomm-list">
                    <li
                        class="recomm-item"
                        v-for="(row, key) in recommGroupData"
                        :key="key"
                        >
                        <router-link :to="{ path: '/artist/list', query: { contents: row.contents, recommGroupUid: row.recommGroupUid } }" :recommGroupUid="row.recommGroupUid">
                            <img :src="$lib.cdnUrl(row.imgUrl)" :alt="row.contents">
                        </router-link>
                        <!-- <PuSkeleton class="ui-bg-img" :loading="recommImgLoading" width="48rem" height="12rem">
                            <router-link :to="{ path: '/artist/list', query: { contents: row.contents, recommGroupUid: row.recommGroupUid } }" :recommGroupUid="row.recommGroupUid">
                                <img :src="$lib.cdnUrl(row.imgUrl)" :alt="row.contents">
                            </router-link>
                        </PuSkeleton> -->
                    </li>
                </ul>
            </div>

            <div class="home-information">
                <!-- <div class="info-list">
          <div class="info-item">
            <icon src="img/illustration/girl.png" width="18.3rem" height="29.5rem" :cover="false" />
            <div class="title">이용안내</div>
            <div class="body">{{'헤이비글의 이용안내를 보시고\n더욱더 편리한 서비스를\n이용해보세요!'}}</div>
          </div>
        </div> -->
                <div class="info-company">
                    <label-button
                        title="헤이비글"
                        title-color="#333"
                        :title-weight="600"
                    >
                    </label-button>
                    <transition name="fade">
                        <div class="toggle-area" v-show="showCompanyInfo">
                            (주)윔즈 | 대표 : 추인화 | 사업자등록번호
                            <span>413</span>-<span>81</span>-<span>06475</span
                            ><br />
                            통신판매업신고 : 제 2018-서울서초-1589호<br />
                            <br />
                            주소 : 서울시 중구 서소문로 89, 1704호<br />
                            COPYRIGHT 2016 WIMZ. ALL RIGHTS RESERVED.
                        </div>
                    </transition>
                    <label-button
                        title="고객센터"
                        title-color="#333"
                        :title-weight="600"
                        style="margin: 2.6rem 0 2.4rem;"
                    >
                    </label-button>
                    <div class="call-center">
                        <a href="tel:02-3144-1806" class="call-center-num"
                            >02-3144-1806</a
                        ><br />
                        메일 : heybeagle@wimz.co.kr <br />
                        운영시간 : 평일 10:00 ~ 19:00 (점심 13:00 ~ 14:00)<br />
                        <!-- <small>(주)윔즈는 통신판매중개자로서 통신판매의 당사자가 아니며 상품의 예약, 이용 및 환불 등과 관련된 의무와 책임은 각 판매자에게 있습니다.</small> -->
                        예약, 이용 및 환불 등 관련 민원은 고객센터로 연락
                        바랍니다.
                    </div>
                    <ul class="sns-list">
                        <li>
                            <a href="https://www.instagram.com/heybeagle"
                                ><img
                                    src="/img/sns/instagram.png"
                                    alt="헤이비글 인스타그램"
                            /></a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/@HeyBeagle"
                                ><img
                                    src="/img/sns/youtube.png"
                                    alt="헤이비글 유튜브"
                            /></a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import Icon from '@/components/common/Icon'
import AItem from '@/components/artist/AItem'
import AHotItem from '@/components/artist/AHotItem'
import OItem from '@/components/client/OItem'
import OMainList from '@/components/offer/OMainList'
import GenreIconList from '@/components/common/GenreIconList'
import LabelButton from '@/components/common/LabelButton'
import AMiniList from '@/components/artist/AMiniList'
import AMovList from '@/components/artist/AMovList'
import TagBox from '@/components/common/TagBox'
import Badge from '@/components/common/Badge'
import AMiniItem from '@/components/artist/AMiniItem'

import { useCacheStore } from '@/pinia/modules/cache'
import ChannelService from '@/plugins/ChannelService'

export default {
    name: 'Home',
    components: {
        PageHeader,
        Icon,
        AItem,
        AHotItem,
        OItem,
        OMainList,
        GenreIconList,
        LabelButton,
        AMiniList,
        AMovList,
        TagBox,
        Badge,
        AMiniItem,
    },
    data() {
        return {
            init: false,
            history: {
                exit: false,
            },
            offerData: [],
            recentOfferData: [],
            recommGroupData: [],
            // 다른 분들의 Pick
            pickArtistData: [],
            // 최근 1:1오퍼 많이 받은 전문가
            directOfferArtistData: [],
            // 최근 결제된 전문가
            offerPayArtistData: [],
            /**
             * 최근 섭외(결제)된 의뢰 목록 (direct포함해서 모두 출력)
             * 접속자가 전문가일때만 출력함
             */
            offerProData: [],
            // 메인 유튜브 영상 (일반회원일 경우에만)
            mainMovData: [],
            // 통계정보
            mainAvgData: {
                // 의뢰수
                offerTotalCount: 0,
                // 등록금액
                offerMaxPriceSum: 0,
                // 전문가수
                artistTotalCount: 0,
                // 등록 견적서 수
                artistProTotalCount: 0,
            },
            // 아티스트 키워드
            artistKeywordData: [],
            /**
             * 메인 메뉴 그룹데이터
             * @type {IMainMenuData[]}
             */
            mainMenuData: [],
            showCompanyInfo: true,
            offset: {
                offerData: 0,
            },
            que: {
                offerData: false,
            },
            scroll: {
                lastY: 0,
            },
            tooltip: {
                profile: false,
            },
            channelIOBoot: false,
            recommImgLoading: true,
            rollingBanner : [
                {id:0, image:'/img/customers/samsung.png' ,alt:'삼성전자'},
                {id:1, image:'/img/customers/lotte-drink.png' ,alt:'롯데칠성음료'},
                {id:2, image:'/img/customers/lotte-hotel.png' ,alt:'롯데호텔'},
                {id:3, image:'/img/customers/nh.png' ,alt:'nh'},
                {id:4, image:'/img/customers/kt.png' ,alt:'kt'},
                {id:5, image:'/img/customers/seocho.png' ,alt:'서초구'},
                {id:6, image:'/img/customers/yongsan.png' ,alt:'용산구'},
                {id:7, image:'/img/customers/yeongdeungpo.png' ,alt:'영등포구'},
                {id:8, image:'/img/customers/red-cross.png' ,alt:'대한적십자사'},
                {id:9, image:'/img/customers/kinemaster.png' ,alt:'kinemaster'},
                {id:10, image:'/img/customers/sap.png' ,alt:'sap'},
                {id:11, image:'/img/customers/sofitel.png' ,alt:'sofitel'},
                {id:12, image:'/img/customers/bandi.png' ,alt:'bandi'},
                {id:13, image:'/img/customers/phoneix-darts.png' ,alt:'phoneixdarts'},
                {id:14, image:'/img/customers/seoul-science.png' ,alt:'서울과학기술대학교'},
            ]
        }
    },
    computed: {
        userState: {
            get() {
                return this.$store.state.user
            },
        },
        isClient: {
            get() {
                return this.userState.userData.category === 'client'
            },
        },
        isArtist: {
            get() {
                return this.userState.userData.category === 'artist'
            },
        },
        artistData: {
            get() {
                return this.profileList.find(v => v.isPickPro == 1 ) || this.profileList[0] ;
            },
        },
        profileList(){
            return this.$store.getters.getArtistProfiles;
        },
        hasNewArtistEvent() {
            return this.$store.getters?.hasNewArtistEvent || false
        },
        isNewMembershipUser() {
            return this.$store.getters.isNewMembershipUser
        },
        hasEventMemo() {
            return !!this.artistData?.eventMemo
        },
        isEarlybirdEventArtist() {
            return (
                this.artistData?.saleType === 'earlybird' &&
                this.artistData?.salePer > 0
            )
        },
        hasNewMsg() {
            return this.$store.getters.hasNewMsg
        },
        chUserData() {
            return this.$store.getters.getChUserData
        },
        hasOfferForTomorrow() {
            if (!this.isArtist) return false

            const date = this.moment()
                .add(1, 'd')
                .startOf('day')
            const startMs = date.valueOf()
            const endMs = date.add(1, 'd').valueOf()

            const offerProData = this.offerProData ?? []

            return offerProData.some((row) => {
                const playMs = (Number(row.playDate) || 0) * 1000

                return playMs >= startMs && playMs < endMs
            })
        },
        showProfile(){
            return this.profileList ? this.profileList.length > 0 : false;
        }
    },
    created() {
        this.channelIOBoot = false
        this.getArtistProfiles()
        this.$eventBus
            .$on('refresh', (route) => {
                if (route.path === '/home') {
                    this.$nextTick()
                        .then(() => {
                            this.$refs?.container?.scrollTo?.({ top: 0 })
                        })
                        .then(() => {
                            if (this.isArtist) {
                                this.getOfferData('artist').then(() =>
                                    this.getOfferData('init')
                                )
                                this.getArtistProfiles()
                            }
                            this.$parent?.getEtcMain?.()
                        })
                }
            })
            .$on('getEtcMain', (data) => this.getEtcMain(data))

        ChannelService.loadScript()
    },
    beforeMount() {
        if (this.isArtist) {
            this.$store.dispatch('getArtistEventList')
            this.getOfferData('artist').then(() => this.getOfferData())
        }
    },
    mounted() {
        // setTimeout(() => {
        //     this.loadRecommImages();
        // }, 500)    

        this.$nextTick(() => {
        if (this.isArtist) {
            this.$refs.container.addEventListener('scroll', this.getOfferDataScroll, false)
        }
        this.init = true
        setTimeout(() => {
            if (this.hasNewMsg && this.$route.name === 'home') {
            this.$router.push('/message/list')
            }
        }, 500)
        })
        this.startRolling();
    },
    beforeRouteEnter(to, from, next) {
        const cacheStore = useCacheStore()

        next((Vue) => {
            cacheStore.clearForHomeScreen()

            if (Vue.init) {
                Vue.$refs.container.scrollTop = Vue.scroll.lastY
                if (Vue.isArtist) {
                    Vue.$store.dispatch('getArtistEventList')
                    Vue.getOfferData('artist')
                    Vue.getArtistProfiles()
                }
            }
        })
    },
    beforeRouteLeave(to, from, next) {
        setTimeout(() => {
            if (this.$store.state.history.back) {
                this.$runToApp({
                    androidCb: () => {
                        if (!this.history.exit) {
                            this.$toast(
                                '뒤로 버튼을 한번 더 누르시면 종료됩니다.'
                            )
                            this.history.exit = true
                            setTimeout(() => (this.history.exit = false), 2000)
                        } else {
                            location.href = 'hbscheme://appExit'
                        }
                        next(false)
                    },
                    iosCb: () => next(false),
                    webCb: () => next(false),
                })
            } else {
                this.scroll.lastY = this.$refs.container.scrollTop
                next()
            }
        })
    },
    methods: {
        /**
         * @param {'artist'|'init} [mode]
         */
        getOfferData(mode = null) {
            if (this.que.offerData) return Promise.resolve()
            if (!mode) this.que.offerData = true

            const req = {
                method: 'get',
                url: `/client/offer/${this.offset.offerData}/10`,
            }

            if (mode === 'artist') {
                req.url = '/artist/offerPro/0/10'
            } else if (mode === 'init') {
                this.offerData = []
                this.offset.offerData = 0
                req.url = `/client/offer/${this.offset.offerData}/10`
            }

            return this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        if (mode === 'artist') {
                            this.recentOfferData = data.response.offerData || []
                        } else {
                            let offerData = data.response.offerData || []
                            this.offerData = this.offerData.concat(offerData)
                            this.offset.offerData += offerData.length
                        }
                    } else {
                        this.$alert(resultData)
                    }
                    return
                })
                .catch((e) => {
                    log.error(e)
                    return
                })
                .then(() => {
                    if (!mode) {
                        setTimeout(() => {
                            this.que.offerData = false
                        }, 1000)
                    }
                    return
                })
        },
        getOfferDataScroll() {
            if (this.$route.path !== '/home') return

            let scrollTop = this.$refs.container.scrollTop
            let scrollHeight = this.$refs.container.scrollHeight

            if (
                this.isArtist &&
                scrollHeight <= scrollTop + window.innerHeight
            ) {
                this.getOfferData()
            }
        },
        getArtistProfiles() {
            this.$store.dispatch('getProfileList')
        },
        getEtcMain(data = {}) {
            let mainAvgData = data?.response?.mainAvgData
            if (this.isClient) {
                const mainMenuData = data?.response?.mainMenuData || []

                this.recentOfferData = data?.response?.recentOfferData || []
                this.recommGroupData = data?.response?.recommGroupData || []
                this.pickArtistData = data?.response?.pickArtistData || []
                this.directOfferArtistData =
                    data?.response?.directOfferArtistData || []
                this.offerPayArtistData =
                    data?.response?.offerPayArtistData || []
                this.mainMovData = data?.response?.mainMovData || []
                this.artistKeywordData = data?.response?.artistKeywordData || []
                this.mainMenuData = mainMenuData

                const profile = {
                    userName: this.chUserData.userName,
                    phone: this.chUserData.phone,
                    lastLogin: this.chUserData.loginDate,
                    joinDate: this.chUserData.createdDate,
                    loginCnt: this.chUserData.loginCount,
                    userType: this.chUserData.category,
                    userIdx: this.chUserData.useridx,
                    payCnt: this.chUserData.payCnt,
                    lastPayDate: this.chUserData.lastPayDate,
                    offerCnt: this.chUserData.offerCnt,
                    lastOfferDt: this.chUserData.lastOfferDt,
                    favoritesCnt: this.chUserData.favoritesCnt,
                    memberId: this.chUserData.useridx,
                }

                // 날짜 포맷
                profile.lastOfferDt =
                    profile.lastOfferDt && profile.lastOfferDt != null
                        ? moment(profile.lastOfferDt * 1000).format(
                              'YYYY.MM.DD HH:mm'
                          )
                        : null
                profile.joinDate = profile.joinDate
                    ? moment(profile.joinDate * 1000).format('YYYY.MM.DD HH:mm')
                    : null
                profile.lastPayDate = profile.lastPayDate
                    ? moment(profile.lastPayDate * 1000).format(
                          'YYYY.MM.DD HH:mm'
                      )
                    : null
                profile.lastLogin = profile.lastLogin
                    ? moment(profile.lastLogin * 1000).format(
                          'YYYY.MM.DD HH:mm'
                      )
                    : null

                if (typeof window.ChannelIO != 'function') {
                    ChannelService.loadScript()
                }
                if (!this.channelIOBoot) {
                    ChannelService.boot(
                        {
                            pluginKey: '622f1426-3d4a-4122-9256-5b28bbc7c317', // fill your plugin key
                            memberId: profile.userIdx,
                            profile: profile,
                            hideChannelButtonOnBoot: true,
                        },
                        function onBoot(err) {
                            if (err) {
                                console.log('error : ', err)
                            } else {
                                ChannelService.updateUser(
                                    profile,
                                    function onUpdateUser(err, user) {
                                        if (err) {
                                            console.log(
                                                'update user error',
                                                err
                                            )
                                        } else {
                                            console.log(
                                                'success user update memberId : ' +
                                                    user.userIdx
                                            )
                                        }
                                    }
                                )
                            }
                        }
                    )
                    this.channelIOBoot = true
                }
            }

            if (this.isArtist) {
                this.offerProData = data?.response?.offerProData || []
            }

            if (mainAvgData) {
                this.mainAvgData.offerTotalCount = Number(
                    mainAvgData.offerTotalCount ||
                        this.mainAvgData.offerTotalCount
                )
                this.mainAvgData.offerMaxPriceSum = Number(
                    mainAvgData.offerMaxPriceSum ||
                        this.mainAvgData.offerMaxPriceSum
                )
                this.mainAvgData.artistTotalCount = Number(
                    mainAvgData.artistTotalCount ||
                        this.mainAvgData.artistTotalCount
                )
                this.mainAvgData.artistProTotalCount = Number(
                    mainAvgData.artistProTotalCount ||
                        this.mainAvgData.artistProTotalCount
                )
            }
        },
        companyInfoToggle() {
            this.showCompanyInfo = !this.showCompanyInfo
            this.$nextTick().then(() => {
                this.$refs.container.scrollTop = this.$refs.container.scrollHeight
            })
        },
        showTooltip(name) {
            this.tooltip[name] = true
            setTimeout(() => {
                this.tooltip[name] = false
            }, 2000)
        },
        searchArtistByAdminMemo(adminMemo) {
            this.$router.push({
                path: '/artist/list',
                query: {
                    adminMemo,
                    disableResetBackHistory: 'true',
                    hiddenSearchConditionController: 'true',
                    resetDataOnBack: 'true',
                    sort: 'view',
                },
            })
        },
        loadRecommImages() {
            let loadImgCnt = 0

            this.recommGroupData.forEach((data) => {
                const img = new Image()
                img.src = this.$lib.cdnUrl(data.imgUrl)

                img.onload = () => {
                    loadImgCnt += 1
                    if (loadImgCnt == this.recommGroupData.length) {
                        this.recommImgLoading = false
                    }
                }

                img.onerror = (e) => {
                    loadImgCnt += 1
                    if (loadImgCnt == this.recommGroupData.length) {
                        this.recommImgLoading = false
                    }
                }
            })
        },
        startRolling() {
            let rollingList = this.$refs.rollingList;

            setInterval(function() {
                let rollingItemFirst = rollingList.firstChild;
                const clonedItem = rollingItemFirst.cloneNode(true);

                rollingList.style.transition = '1s';
                rollingList.style.top = '-17.55px';
                setTimeout(function() {
                    rollingList.appendChild(clonedItem);
                    rollingList.style.transition = '0s';
                    rollingList.style.top = "0px";
                    rollingItemFirst.remove();
                },1000)
            }, 2500);
            
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.main-page-container {
    padding: 0 2.8rem;
}
#page > .container {
    padding-bottom: 0;
    &.client {
        background-color: $main-bg-color;
        h3:not(.ql-editor *) {
            height: auto;
            margin-top: 1.2rem;
            margin-bottom: 3.1rem;
            line-height: normal;
            font-size: 2.8rem;
            letter-spacing: -0.14px;
            .btn-more {
                width: 28px;
                height: 28px;
            }
        }
    }
}

header {
    padding: 0 0 4rem;
}

.header-top {
    margin: 0 -2.8rem;
    padding: 2rem 2.8rem;
    border-bottom: 2px solid $border-color;
    background-color: $color-white;
    .logo {
        display: block;
        width: 127px;
        img {
            display: block;
            width: 100%;
        }
    }
}

.customers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -2.8rem;
    padding: 1.5rem 2.8rem;
    background-color: $color-white;
    .rolling-list-wrap {
        overflow: hidden;
        width: 100px;
        height: 17.55px;
        .rolling-list {
            position: relative;
            top: 0;
            li {
                width: 100px;
                height: 17.55px;
                img {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    .customers-txt {
        margin-left: 1.2rem;
        font-size: 1.8rem;
        line-height: 2.5rem;
    }
}

.genre-icon-list {
    padding: 5.2rem 2.8rem 2.4rem;
    margin: 0 -2.8rem;
    background-color: #fff;
}
.artist-profile {
    overflow: hidden;
}

.profile-tooltip {
    position: relative;
    .profile-tooltip-toast {
        opacity: 0;
        position: absolute;
        top: -9rem;
        left: 0;
        right: 0;
        transition: 0.3s;
        line-height: normal;
        &.show {
            opacity: 1;
        }
    }
    // .profile-tooltip-btn:hover ~ .profile-tooltip-toast {
    //   opacity: 1;
    // }
}

.recomm-wrap {
    margin: 1.2rem -2.8rem 0;
    padding: 4rem 2.8rem 5.2rem;
    background-color: $color-white;
}
.recomm-list {
    margin: 0 auto;
    max-width: 48rem;

    .recomm-item {
        overflow: hidden;
        margin-bottom: 3.2rem;
        border-radius: 1rem;
        background-color: #d8d8d8;

        a {
            display: block;
        }

        img {
            width: 100%;
            max-width: 48rem;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.home-information {
    margin: 0 -2.8rem;
    padding: 5.2rem 2.8rem;
    background-color: #f5f5f5;
    .info-list {
        width: 100%;
        max-width: 768px;
        height: 29.5rem;
        .info-item {
            width: inherit;
            height: inherit;
            position: relative;
            background-color: #7ad8e4;
            .icon {
                position: absolute;
                left: 3rem;
                bottom: 0;
            }
            .title {
                position: absolute;
                top: 7.2rem;
                left: 26rem;
                font-size: 3rem;
                color: #fff;
            }
            .body {
                position: absolute;
                top: 13rem;
                left: 26rem;
                font-size: 1.6rem;
                white-space: pre-line;
                color: #fff;
            }
        }
    }
    .info-company {
        color: #999;
        .ui-label-button {
            // padding-left: 3rem;
            .ui-label-button-text {
                line-height: normal !important;
            }
        }
        .toggle-area {
            padding: 2.4rem 0;
            color: inherit;
            font-size: 1.8rem;
            line-height: 2.6rem;
            font-weight: 500;
            a {
                color: inherit;
            }
        }
    }

    .call-center {
        font-size: 1.8rem;
        line-height: 2.6rem;
        letter-spacing: -0.09px;
        font-weight: 500;
        .call-center-num {
            margin-bottom: 0.4rem;
            color: #ab54bb;
            font-size: 2.8rem;
            font-weight: 600;
            line-height: 3.6rem;
            letter-spacing: -0.14px;
        }
    }
    .sns-list {
        display: flex;
        margin-top: 1.6rem;
        li {
            a {
                display: block;
                width: 3.3rem;
                img {
                    display: block;
                    width: 100%;
                }
            }
            & ~ li {
                margin-left: 1.8rem;
            }
        }
    }
}

.main-avg-data {
    ul {
        display: flex;
        flex: 1;
        justify-content: space-evenly;
        padding: 13px 0;
        margin: 1rem 0;
        height: 4.6rem;
        li {
            display: inline;
            font-size: 1.9rem;
            color: #555;
            b {
                font-weight: 600;
                color: #000000;
            }
        }
    }
}

.consulting-block {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 5.6rem;
    margin: 0.8rem 0;

    p {
        width: 100%;
        color: #000;
        font-weight: 500;
        font-size: 1.9rem;
        margin: 0;
        margin-left: 0rem;
        text-align: center;
        height: 4rem;
        line-height: 4.4rem;
        letter-spacing: -0.3px;

        button {
            line-height: normal;
            font-size: 1.9rem;
            font-weight: 500;
            color: #a059b6;
            background-color: transparent;
            border: 0px solid $color-deepLavender;
            margin: 0;
            padding: 0 1rem;
            border-radius: 0.8rem;
            float: none;
            letter-spacing: -0.5px;
        }
    }
}

.home--no-show {
    display: flex;
    align-items: center;
    margin-top: 1.2rem;
    background-color: #f0f3f9;

    .home--no-show--text {
        font-size: 1.9rem;
        line-height: 27.5px;
        color: #000;
        font-weight: 400;
        flex: 1;

        b {
            font-weight: 600;
        }
    }

    .home--no-show--icon {
        margin-left: 27px;
    }
}

.home-title {
    display: flex;
    align-items: center;

    .home-title--text {
        flex: 1;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
