var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isCancel
    ? _c(
        "div",
        { staticClass: "offer-item", class: { "use-time": _vm.time } },
        [
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  return _vm.gotoOffer(_vm.row)
                }
              }
            },
            [
              _vm.map
                ? _c("daum-map", {
                    attrs: {
                      width: _vm.$store.state.app.width * 0.8 + "px",
                      height: _vm.$store.state.app.width * 0.8 * 0.37 + "px",
                      x: _vm.row.geoX,
                      y: _vm.row.geoY
                    }
                  })
                : _vm._e(),
              _c("div", { staticClass: "offer-item-icon-box" }, [
                _vm.dDay >= 0
                  ? _c("div", { staticClass: "offer-item-icon badge-dDay" }, [
                      _vm._v(_vm._s(_vm.dDay > 0 ? "D - " + _vm.dDay : "오늘"))
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "offer-item-eventType" }, [
                _vm._v(
                  _vm._s(
                    _vm.$lib.t_genre(_vm.row.genre || _vm.row.categoryName)
                  )
                ),
                _c("span", [_vm._v("|")]),
                _vm._v(_vm._s(_vm.row.eventType))
              ]),
              _c("div", { staticClass: "offer-item-info" }, [
                _c(
                  "div",
                  { staticClass: "offer-item-info-date" },
                  [
                    _c("icon", {
                      staticClass: "icon",
                      attrs: {
                        src: "img/common/calendar.png",
                        width: "2.5rem",
                        height: "2.5rem",
                        cover: false
                      }
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .moment(_vm.row.playDate * 1000)
                            .format(
                              _vm.time
                                ? "YYYY.MM.DD [/] a h[시] m[분]"
                                : "YYYY.MM.DD"
                            )
                        ) +
                        " "
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "offer-item-info-addr" },
                  [
                    _c("icon", {
                      staticClass: "icon",
                      attrs: {
                        src: "img/common/map-ico.png",
                        width: "1.8rem",
                        height: "2.5rem",
                        cover: false
                      }
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.$lib.addrMinify(_vm.row.address)) + " "
                    )
                  ],
                  1
                )
              ]),
              _vm.money
                ? [
                    _c("div", { staticClass: "offer-item-money" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$lib.moneyRangeFormat(_vm.row)) + " "
                      )
                    ])
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm.footer
            ? [
                !_vm.isArtist
                  ? [
                      _c("div", {
                        staticClass: "ui-border-line ui-m-3 ui-mt-2 ui-mb-2"
                      }),
                      _vm.row.mode === "direct"
                        ? _c(
                            "div",
                            {
                              staticClass: "offer-item-recCount ui-btn direct"
                            },
                            [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        "/artist/profile/" + _vm.row.artistidx
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("p", {
                                    staticClass: "float-left ui-bg-img",
                                    style: {
                                      backgroundImage:
                                        "url(" +
                                        _vm.$lib.cdnUrl(_vm.row.teamImgUrl) +
                                        ")"
                                    }
                                  }),
                                  _c("p", { staticClass: "float-left" }, [
                                    _vm._v(_vm._s(_vm.row.teamName))
                                  ])
                                ]
                              ),
                              _vm.row.recCount > 0
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "float-right",
                                      on: {
                                        click: function() {
                                          return _vm.$router.push(
                                            (_vm.myOffer
                                              ? "/client/my-offer"
                                              : "/offer") +
                                              "/" +
                                              _vm.row.offerUid +
                                              "/" +
                                              _vm.row.artistidx
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" 견적보기 ")]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "offer-item-recCount ui-btn",
                              on: {
                                click: function() {
                                  return _vm.$router.push(
                                    (_vm.myOffer
                                      ? "/client/my-offer"
                                      : "/offer") +
                                      "/" +
                                      _vm.row.offerUid +
                                      "/recruiter"
                                  )
                                }
                              }
                            },
                            [
                              _c("p", { staticClass: "float-left" }, [
                                _vm._v("지원자")
                              ]),
                              _c("p", { staticClass: "float-right" }, [
                                _vm._v(
                                  _vm._s(_vm.$lib.addComma(_vm.row.recCount)) +
                                    "명"
                                )
                              ])
                            ]
                          )
                    ]
                  : _vm.isArtist
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "offer-btn ui-btn",
                          on: {
                            click: function($event) {
                              return _vm.gotoProfile(_vm.row)
                            }
                          }
                        },
                        [_vm._v(" 지원한 내용보기 ")]
                      )
                    ]
                  : _vm._e()
              ]
            : _vm._e(),
          _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-2" })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }