<template>
    <div class="a-mov-list" v-if="total > 0">
        <div
            class="a-mov-list-scrollView"
            :style="{
                width: `${scrollWidth}px`,
            }"
        >
            <template v-for="(row, key) in data">
                <a-mov-item
                    :key="key"
                    :row="row"
                    :style="{
                        marginRight: `${key < total - 1 && margin || 0}px`,
                    }"
                />
            </template>
        </div>
    </div>
</template>

<script>
import AMovItem from '@/components/artist/AMovItem'

export default {
    name: 'AMovList',
    components: {
        AMovItem,
    },
    data() {
        return {
            paddingLeft: 0,
            paddingRight: 0,
        }
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        width() {
            return 330
        },
        margin() {
            return 27
        },
        total() {
            return this.data.length
        },
        scrollWidth() {
            return (this.width + this.margin) * this.total - this.margin + this.paddingRight
        },
    },
    watch: {
        total() {
            this.setPaddingX()
        }
    },
    mounted() {
        this.setPaddingX()
    },
    methods: {
        setPaddingX() {
            if (this.$el && this.total > 0) {
                let { paddingLeft, paddingRight } = window?.getComputedStyle?.(this.$el)
                this.paddingLeft = parseInt(paddingLeft, 10) || 0
                this.paddingRight = parseInt(paddingRight, 10) || 0
            } else {
                this.paddingLeft = 0
                this.paddingRight = 0
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.a-mov-list {
    overflow-y: hidden;
    overflow-x: auto;

    .a-mov-list-scrollView {
        display: flex;
        flex-direction: row;
    }
}
</style>
