<template>
    <ul class="genre-icon-list">
        <li
            class="genre-icon-item"
            v-for="(row, key) in genreText"
            :key="key"
            :class="{ on: genre.indexOf(row.genre) !== -1 && !noHighlight }"
            @click="
                () =>
                    disabled.indexOf(row.genre) === -1 ? onClick(row) : false
            "
        >   
            <div
                v-if="key===0"
                class="icon ui-bg-img-contain"
                :style="{
                    // backgroundImage: `url(${$lib.cdnUrl(`cdn/icons/new_genre/${genre.indexOf(row.genre) !== -1 && !noHighlight ? row.iconName + '_on' : row.iconName}.png`)})`,
                    backgroundImage:
                        genre.indexOf(row.genre) !== -1 && !noHighlight
                            ? `url(${$lib.cdnUrl(
                                  `cdn/icons/new_genre/${row.iconName +
                                      '_on'}.png`
                              )})`
                            : `url(${$lib.imgUrl(
                                  `img/genre/${row.iconName}.gif`,
                                  true
                              )})`,
                    width: '8rem',
                    height: '8rem',
                }"
            ></div>
            <div
                v-else
                class="icon ui-bg-img-contain"
                :style="{
                    // backgroundImage: `url(${$lib.cdnUrl(`cdn/icons/new_genre/${genre.indexOf(row.genre) !== -1 && !noHighlight ? row.iconName + '_on' : row.iconName}.png`)})`,
                    backgroundImage:
                        genre.indexOf(row.genre) !== -1 && !noHighlight
                            ? `url(${$lib.cdnUrl(
                                  `cdn/icons/new_genre/${row.iconName +
                                      '_on'}.png`
                              )})`
                            : `url(${$lib.imgUrl(
                                  `img/genre/${row.iconName}.svg`,
                                  true
                              )})`,
                    width: '8rem',
                    height: '8rem',
                }"
            ></div>
            <p>{{ $lib.t_genre(row.genre || row.categoryName) }}</p>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array],
            default: null,
        },
        isMulti: {
            type: Boolean,
            default: false,
        },
        noHighlight: {
            type: Boolean,
            default: false,
        },
        disabled: {
            tyle: Array,
            default: () => [],
        },
    },
    data() {
        return {
            genre: [],
        }
    },
    computed: {
        genreText: {
            get() {
                const arr: any[] = this.$store.state?.user?.genreText ?? []
                return arr.filter((row) => row.genre !== '기타' && row.genre !== '행사패키지' && row.genre !== '강연자')
            },
        },
    },
    created() {
        this.genre = [].concat(this.value)
    },
    methods: {
        onClick(row) {
            if (this.isMulti) {
                let idx = this.genre.indexOf(row.genre)
                if (idx === -1) {
                    this.genre.push(row.genre)
                } else {
                    this.genre.splice(idx, 1)
                }
            } else {
                this.genre = [].concat([row.genre])
            }

            this.$emit('click', row.genre)
            this.$emit('result', this.genre)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.genre-icon-list {
    display: flex;
    flex-wrap: wrap;

    .genre-icon-item {
        width: 20%;
        margin-bottom: 2.8rem;
        text-align: center;
        color: #4f4f51;
        flex-shrink: 0;

        a {
            display: block;
            color: #4f4f51;
        }

        .icon {
            margin: 0 auto;
            border-radius: 1.2rem;
            background-size: 80%;
            background-color: $main-bg-color;
        }

        .icon:hover{
            border: 1px solid #e3e3e3;
            background-color: #ffffff;
        }

        p {
            margin: 2.4rem 0 0 0;
            font-size: 2rem;
            line-height: 2.8rem;
            letter-spacing: -0.1px;
            color: #000;
            font-weight: 600;
            word-break: keep-all;
        }

        &.on {
            color: $color-deepLavender;
            a {
                color: $color-deepLavender;
            }
            .icon {
                background-size: 100%;
            }
        }
    }
}
</style>
