import { render, staticRenderFns } from "./OMainItem.vue?vue&type=template&id=4fc70008&scoped=true&"
import script from "./OMainItem.vue?vue&type=script&lang=js&"
export * from "./OMainItem.vue?vue&type=script&lang=js&"
import style0 from "./OMainItem.vue?vue&type=style&index=0&id=4fc70008&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fc70008",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jeonjihyeon/dev/wimz/HeyBeagle_webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4fc70008')) {
      api.createRecord('4fc70008', component.options)
    } else {
      api.reload('4fc70008', component.options)
    }
    module.hot.accept("./OMainItem.vue?vue&type=template&id=4fc70008&scoped=true&", function () {
      api.rerender('4fc70008', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/offer/OMainItem.vue"
export default component.exports