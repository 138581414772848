var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "daum-map",
      on: {
        click: function($event) {
          return _vm.$emit("click", true)
        }
      }
    },
    [
      _c("div", {
        ref: "Map",
        staticClass: "map-container",
        style: { width: _vm.width_, height: _vm.height }
      }),
      _c("div", {
        staticClass: "map-cover",
        style: { width: _vm.width_, height: _vm.height }
      }),
      _c("div", {
        staticClass: "map-marker",
        style: { top: _vm.top, left: _vm.left }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }