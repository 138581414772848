var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "main-page-container container home",
      class: _vm.isClient ? "client" : ""
    },
    [
      _c(
        "header",
        {
          style: {
            // marginTop: isClient ? '0' : '40px', 전문가 header 상단 영역 수정
            paddingBottom: "0rem"
          }
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "customers" }, [
            _c("div", { staticClass: "rolling-list-wrap" }, [
              _c(
                "ul",
                { ref: "rollingList", staticClass: "rolling-list" },
                _vm._l(_vm.rollingBanner, function(items) {
                  return _c("li", { key: items.id }, [
                    _c("span", { staticClass: "customers-logo" }, [
                      _c("img", { attrs: { src: items.image, alt: items.alt } })
                    ])
                  ])
                }),
                0
              )
            ]),
            _c("span", { staticClass: "customers-txt" }, [
              _vm._v("임직원들도 헤이비글과 함께했어요.")
            ])
          ]),
          _c("page-header", {
            attrs: {
              title: _vm.isClient
                ? "완벽한 행사,\n                    10분 만에 준비하세요"
                : !_vm.hasOfferForTomorrow
                ? "안녕하세요"
                : _vm.$store.state.user.userData.userName + "님,",
              subtitle: _vm.isClient ? "" : "",
              category: _vm.userState.userData.category,
              titleForArtist:
                (_vm.hasOfferForTomorrow &&
                  "내일 행사가 있으시네요.\n잘 준비하세요!") ||
                ""
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "main-avg-data" }, [
        _vm.isArtist
          ? _c("ul", [
              _c("li", [
                _vm._v(" 누적 의뢰 "),
                _c("b", [
                  _vm._v(
                    _vm._s(_vm.$lib.addComma(_vm.mainAvgData.offerTotalCount))
                  )
                ]),
                _vm._v("건 ")
              ]),
              _c("li", [
                _vm._v(" 등록금액 "),
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.$lib.moneyFormat(_vm.mainAvgData.offerMaxPriceSum)
                    )
                  )
                ]),
                _vm._v("원 ")
              ])
            ])
          : _vm._e()
      ]),
      _vm.isArtist
        ? [
            _c("div", {
              staticClass: "ui-border-line ui-h-1",
              staticStyle: { height: "1px", "background-color": "#e4e4e4" }
            }),
            !_vm.showProfile
              ? _c("div", { staticClass: "profile-tooltip ui-mt-0" }, [
                  _c(
                    "h3",
                    [
                      _c("span", [_vm._v("프로필")]),
                      _c("icon", {
                        staticClass: "profile-tooltip-btn float-right ui-btn",
                        attrs: {
                          src: "img/common/info_circle.png",
                          width: "2.2rem",
                          height: "9rem",
                          cover: false
                        },
                        on: {
                          click: function() {
                            return _vm.showTooltip("profile")
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "profile-tooltip-toast toasted primary default",
                          class: { show: _vm.tooltip.profile }
                        },
                        [
                          _vm._v(" 실제 의뢰인들이 보는 프로필 모습이예요."),
                          _c("br"),
                          _vm._v("더 매력적이고 멋지게 관리해보세요. ")
                        ]
                      )
                    ],
                    1
                  ),
                  _c("img", {
                    staticClass: "artist-profile ui-btn ui-mb-3",
                    attrs: {
                      src: _vm.$lib.imgUrl("img/home/no-profile.png"),
                      width: "100%"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/artist/my-profile")
                      }
                    }
                  })
                ])
              : _vm._e(),
            _c("div", {
              staticClass: "ui-border-line",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.isArtist && _vm.offerProData.length
        ? [
            _c(
              "div",
              [
                _c("h3", [_vm._v("섭외된 의뢰")]),
                _c(
                  "p",
                  {
                    style: {
                      color: "#848484",
                      fontSize: "1.8rem",
                      fontWeight: "400",
                      marginTop: "-1em",
                      marginBottom: "1em"
                    }
                  },
                  [_vm._v(" 가까운 행사부터 보여드릴게요. 잊지말고 챙기세요! ")]
                ),
                _c("o-main-list", {
                  attrs: {
                    data: _vm.offerProData,
                    map: false,
                    time: true,
                    money: false,
                    footer: false,
                    myOffer: false
                  }
                })
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.isArtist
        ? [
            _c("div", [
              _c("h3", [_vm._v("전체 의뢰")]),
              _c("div", { staticClass: "ui-border-line ui-mb-3" }),
              _c(
                "ul",
                [
                  _vm._l(_vm.offerData, function(row, key) {
                    return [
                      _c("o-item", {
                        key: key,
                        attrs: { row: row, "is-simple": true }
                      }),
                      _c("div", {
                        key: "line" + key,
                        staticClass: "ui-border-line ui-mt-3 ui-mb-3"
                      })
                    ]
                  })
                ],
                2
              )
            ])
          ]
        : _vm._e(),
      _vm.isClient
        ? [
            _c("genre-icon-list", {
              attrs: { "no-highlight": "" },
              on: {
                click: function(genre) {
                  if (genre == "연예인") {
                    _vm.$router.push({
                      path: "/artist/celebrity",
                      query: { genre: genre }
                    })
                  } else {
                    _vm.$router.push({
                      path: "/artist/list",
                      query: { genre: genre }
                    })
                  }
                }
              }
            }),
            _vm.mainMovData.length > 0
              ? [
                  _c("h3", [_vm._v("이런 공연은 어떠세요?")]),
                  _c("a-mov-list", {
                    style: {
                      margin: "0 -3rem",
                      padding: "0 3rem"
                    },
                    attrs: { data: _vm.mainMovData }
                  }),
                  _c("div", {
                    staticClass: "ui-border-line ui-mt-2 ui-mb-1 ui-h-1",
                    staticStyle: { height: "10px" }
                  })
                ]
              : _vm._e(),
            _vm.directOfferArtistData.length > 0
              ? [
                  _c("div", {
                    staticClass: "ui-border-line ui-mt-3 ui-mb-1 ui-h-1",
                    staticStyle: { height: "10px" },
                    attrs: { "data-v-fae5bece": "" }
                  }),
                  _c("h3", [_vm._v("이번 달 인기 전문가")]),
                  _c("a-mini-list", {
                    style: {
                      margin: "0 -3rem",
                      padding: "0 3rem"
                    },
                    attrs: { data: _vm.directOfferArtistData }
                  }),
                  _c("div", {
                    staticClass: "ui-border-line ui-mt-2 ui-mb-1 ui-h-1",
                    staticStyle: { height: "10px" }
                  })
                ]
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "home--no-show",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/etc/no-show")
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: "img/home/no-show-banner.png",
                    alt: "노쇼, 사기거래 Zero 어디에도 없는 300% 보상제도"
                  }
                })
              ]
            ),
            _c("div", {
              staticClass: "ui-border-line ui-mt-3 ui-mb-1 ui-h-1",
              staticStyle: { height: "10px" },
              attrs: { "data-v-fae5bece": "" }
            }),
            _vm.mainMenuData.length
              ? [
                  _vm._l(_vm.mainMenuData, function(row, key) {
                    return [
                      _c(
                        "div",
                        { key: key },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "text-decoration-none",
                              attrs: {
                                to: {
                                  name: "ClientMainMenuGroupArtistList",
                                  params: {
                                    mainMenuGroupUid: row.mainMenuGroupUid
                                  }
                                }
                              }
                            },
                            [
                              _c("h3", { staticClass: "home-title" }, [
                                _c(
                                  "span",
                                  { staticClass: "home-title--text" },
                                  [_vm._v(_vm._s(row.contents))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "btn-more text-decoration-none"
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("전체 보기")
                                    ])
                                  ]
                                )
                              ])
                            ]
                          ),
                          [
                            _c("a-mini-list", {
                              style: {
                                margin: "0 -3rem",
                                padding: "0 3rem"
                              },
                              attrs: { data: row.artistData },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("a-mini-item", {
                                          attrs: {
                                            imgUrl: item.teamImgUrl,
                                            genre: item.artistGenre || "",
                                            name: item.teamName || "",
                                            artistidx: item.artistidx,
                                            mainMenuArtistUid:
                                              item.mainMenuArtistUid,
                                            hashTag: item.hashTag,
                                            reviewCount: item.reviewCount,
                                            favCount: item.favCount,
                                            pointCount: item.pointCount
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          _c("div", {
                            staticClass:
                              "ui-border-line ui-mt-2 ui-mb-1 ui-h-1",
                            staticStyle: { height: "10px" }
                          })
                        ],
                        2
                      )
                    ]
                  })
                ]
              : _vm._e(),
            _c("div", [
              _c("h3", [_vm._v("기획전")]),
              _c(
                "ul",
                { staticClass: "recomm-list" },
                _vm._l(_vm.recommGroupData, function(row, key) {
                  return _c(
                    "li",
                    { key: key, staticClass: "recomm-item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              path: "/artist/list",
                              query: {
                                contents: row.contents,
                                recommGroupUid: row.recommGroupUid
                              }
                            },
                            recommGroupUid: row.recommGroupUid
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.$lib.cdnUrl(row.imgUrl),
                              alt: row.contents
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _c("div", { staticClass: "home-information" }, [
              _c(
                "div",
                { staticClass: "info-company" },
                [
                  _c("label-button", {
                    attrs: {
                      title: "헤이비글",
                      "title-color": "#333",
                      "title-weight": 600
                    }
                  }),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showCompanyInfo,
                            expression: "showCompanyInfo"
                          }
                        ],
                        staticClass: "toggle-area"
                      },
                      [
                        _vm._v(" (주)윔즈 | 대표 : 추인화 | 사업자등록번호 "),
                        _c("span", [_vm._v("413")]),
                        _vm._v("-"),
                        _c("span", [_vm._v("81")]),
                        _vm._v("-"),
                        _c("span", [_vm._v("06475")]),
                        _c("br"),
                        _vm._v(" 통신판매업신고 : 제 2018-서울서초-1589호"),
                        _c("br"),
                        _c("br"),
                        _vm._v(" 주소 : 서울시 중구 서소문로 89, 1704호"),
                        _c("br"),
                        _vm._v(" COPYRIGHT 2016 WIMZ. ALL RIGHTS RESERVED. ")
                      ]
                    )
                  ]),
                  _c("label-button", {
                    staticStyle: { margin: "2.6rem 0 2.4rem" },
                    attrs: {
                      title: "고객센터",
                      "title-color": "#333",
                      "title-weight": 600
                    }
                  }),
                  _vm._m(1),
                  _vm._m(2)
                ],
                1
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-top" }, [
      _c("h1", { staticClass: "logo" }, [
        _c("img", { attrs: { src: "img/logo-b@3x.png", alt: "헤이비글" } })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "call-center" }, [
      _c(
        "a",
        { staticClass: "call-center-num", attrs: { href: "tel:02-3144-1806" } },
        [_vm._v("02-3144-1806")]
      ),
      _c("br"),
      _vm._v(" 메일 : heybeagle@wimz.co.kr "),
      _c("br"),
      _vm._v(" 운영시간 : 평일 10:00 ~ 19:00 (점심 13:00 ~ 14:00)"),
      _c("br"),
      _vm._v(" 예약, 이용 및 환불 등 관련 민원은 고객센터로 연락 바랍니다. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "sns-list" }, [
      _c("li", [
        _c("a", { attrs: { href: "https://www.instagram.com/heybeagle" } }, [
          _c("img", {
            attrs: { src: "/img/sns/instagram.png", alt: "헤이비글 인스타그램" }
          })
        ])
      ]),
      _c("li", [
        _c("a", { attrs: { href: "https://www.youtube.com/@HeyBeagle" } }, [
          _c("img", {
            attrs: { src: "/img/sns/youtube.png", alt: "헤이비글 유튜브" }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }