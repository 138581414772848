var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "genre-icon-list" },
    _vm._l(_vm.genreText, function(row, key) {
      return _c(
        "li",
        {
          key: key,
          staticClass: "genre-icon-item",
          class: {
            on: _vm.genre.indexOf(row.genre) !== -1 && !_vm.noHighlight
          },
          on: {
            click: function() {
              return _vm.disabled.indexOf(row.genre) === -1
                ? _vm.onClick(row)
                : false
            }
          }
        },
        [
          key === 0
            ? _c("div", {
                staticClass: "icon ui-bg-img-contain",
                style: {
                  // backgroundImage: `url(${$lib.cdnUrl(`cdn/icons/new_genre/${genre.indexOf(row.genre) !== -1 && !noHighlight ? row.iconName + '_on' : row.iconName}.png`)})`,
                  backgroundImage:
                    _vm.genre.indexOf(row.genre) !== -1 && !_vm.noHighlight
                      ? "url(" +
                        _vm.$lib.cdnUrl(
                          "cdn/icons/new_genre/" +
                            (row.iconName + "_on") +
                            ".png"
                        ) +
                        ")"
                      : "url(" +
                        _vm.$lib.imgUrl(
                          "img/genre/" + row.iconName + ".gif",
                          true
                        ) +
                        ")",
                  width: "8rem",
                  height: "8rem"
                }
              })
            : _c("div", {
                staticClass: "icon ui-bg-img-contain",
                style: {
                  // backgroundImage: `url(${$lib.cdnUrl(`cdn/icons/new_genre/${genre.indexOf(row.genre) !== -1 && !noHighlight ? row.iconName + '_on' : row.iconName}.png`)})`,
                  backgroundImage:
                    _vm.genre.indexOf(row.genre) !== -1 && !_vm.noHighlight
                      ? "url(" +
                        _vm.$lib.cdnUrl(
                          "cdn/icons/new_genre/" +
                            (row.iconName + "_on") +
                            ".png"
                        ) +
                        ")"
                      : "url(" +
                        _vm.$lib.imgUrl(
                          "img/genre/" + row.iconName + ".svg",
                          true
                        ) +
                        ")",
                  width: "8rem",
                  height: "8rem"
                }
              }),
          _c("p", [
            _vm._v(_vm._s(_vm.$lib.t_genre(row.genre || row.categoryName)))
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }