var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "offer-list-box",
      style: { margin: _vm.data.length === 1 ? "0 auto" : null }
    },
    [
      _c(
        "div",
        {
          staticClass: "offer-list",
          style: { width: _vm.data.length * 45 + 2 + "rem" }
        },
        _vm._l(_vm.data, function(row, key) {
          return _c("o-main-item", {
            key: key,
            attrs: {
              row: row,
              map: _vm.map,
              time: _vm.time,
              money: _vm.money,
              footer: _vm.footer,
              myOffer: _vm.myOffer
            }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }