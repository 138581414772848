var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.total > 0
    ? _c("div", { staticClass: "a-mov-list" }, [
        _c(
          "div",
          {
            staticClass: "a-mov-list-scrollView",
            style: {
              width: _vm.scrollWidth + "px"
            }
          },
          [
            _vm._l(_vm.data, function(row, key) {
              return [
                _c("a-mov-item", {
                  key: key,
                  style: {
                    marginRight:
                      ((key < _vm.total - 1 && _vm.margin) || 0) + "px"
                  },
                  attrs: { row: row }
                })
              ]
            })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }