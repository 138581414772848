var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "a-mov-item",
      class: { "show-player": _vm.isVideoReady },
      style: { width: (_vm.width || 0) + "px" }
    },
    [
      _c(
        "div",
        {
          staticClass: "a-mov-item-thumbnail",
          style: {
            height: _vm.height + "px"
          },
          on: {
            click: function() {
              return _vm.openYoutube()
            }
          }
        },
        [
          _c("MovieThumbnail", {
            staticClass: "thumbnail",
            attrs: {
              thumbnailUrl: _vm.thumbnail,
              movUrl: _vm.movUrl,
              height: _vm.height + "px"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "a-mov-item-info",
          on: {
            click: function() {
              return _vm.gotoProfile()
            }
          }
        },
        [
          _c(
            "PuSkeleton",
            {
              staticClass: "a-mov-item-profile",
              attrs: {
                circle: true,
                loading: _vm.imageLoading,
                width: "35px",
                height: "35px"
              }
            },
            [
              _c("div", {
                staticClass: "a-mov-item-profile",
                style: {
                  backgroundImage:
                    (_vm.teamImgUrl &&
                      "url(" + _vm.$lib.cdnUrl(_vm.teamImgUrl) + ")") ||
                    null
                }
              })
            ]
          ),
          _c("div", { staticClass: "a-mov-item-text" }, [
            _c("span", [_vm._v(_vm._s(_vm.teamName))]),
            _c("span", { staticClass: "mid" }, [_vm._v(">")])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }