<template>
  <div class="daum-map" @click="$emit('click', true)">
    <div ref="Map" class="map-container" :style="{ width: width_, height }"></div>
    <div class="map-cover" :style="{ width: width_, height }"></div>
    <div class="map-marker" :style="{ top, left }"></div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
      default: '375px',
    },
    x: {
      type: [String, Number],
      default: 0,
    },
    y: {
      type: [String, Number],
      default: 0,
    },
    autoMount: {
      type: Boolean,
      default: true,
    },
    level: {
      type: Number,
      default: 5,
    }
  },
  computed: {
    width_() {
      return this.width || this.$store.state.app.width + 'px'
    },
    left() {
      let width = this.width_.match(/^\d+\.?(\d?)+/)
      if (!width) return width
      width = width[0]
      return `${(width / 2) - 70}px`
    },
    top() {
      let height = this.height.match(/^\d+\.?(\d?)+/)
      if (!height) return height
      height = height[0]
      return `${(height / 2) - 70}px`
    },
  },
  mounted() {
    if (this.autoMount) this.setMount(this.setStaticMap)
  },
  methods: {
    setMount(cb = this.setStaticMap) {
      if (!this.x || !this.y) {
        setTimeout(() => {
          this.setMount(cb)
        }, 200)
      } else {
        cb()
      }
    },
    setStaticMap() {
      this.$nextTick().then(() => {
        try {
          const Map = this.$refs.Map

          if (typeof Map === 'object' && Map && 'hasChildNodes' in Map) {
            while (Map.hasChildNodes()) {
              Map.removeChild(Map.firstChild)
            }
          }

          // 이미지 지도에서 마커가 표시될 위치입니다
          const markerPosition = new daum.maps.LatLng(
            Number(this.x),
            Number(this.y)
          )

          // 이미지 지도에 표시할 마커입니다
          // 이미지 지도에 표시할 마커는 Object 형태입니다
          // const marker = {
          //   position: markerPosition,
          // }

          // 이미지 지도를 표시할 div
          const staticMapContainer = Map

          const staticMapOption = {
            center: markerPosition, // 이미지 지도의 중심좌표
            level: this.level, // 이미지 지도의 확대 레벨
            // marker: marker, // 이미지 지도에 표시할 마커
          }

          // 이미지 지도를 생성합니다
          new daum.maps.StaticMap(staticMapContainer, staticMapOption)
        } catch (e) {
          log.error(e)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.daum-map {
  position: relative;
  .map-container {
    max-width: 768px;
  }
  .map-cover {
    position: absolute;
    top: 0;
    left: 0;
  }
  .map-marker {
    position: absolute;
    width: 14rem;
    height: 14rem;
    background-color: rgba($color: #3fa2e4, $alpha: 0.5);
    border: 1px solid #3fa2e4;
    border-radius: 50%;
  }
}
</style>
