<template>
  <div class="offer-item" :class="{ 'use-time': time }" v-if="!isCancel">
    <div @click="gotoOffer(row)">
      <daum-map
        v-if="map"
        :width="`${$store.state.app.width * 0.8}px`"
        :height="`${$store.state.app.width * 0.8 * 0.37}px`"
        :x="row.geoX"
        :y="row.geoY"
      >
      </daum-map>

      <div class="offer-item-icon-box">
        <!-- <div class="offer-item-icon badge-read" v-if="isRead">읽음</div>
        <div class="offer-item-icon badge-hot" v-if="!!row.userReferrer">H</div>
        <div class="offer-item-icon badge-new" v-if="isArtist && !isEnd && row.newOffer === 'y'">N</div> -->
        <!-- <div class="offer-item-icon badge-1-1" v-if="row.mode === 'direct'">1 : 1</div> -->
        <!-- <div class="offer-item-icon badge-end" v-if="isEnd">마감</div> -->
        <div class="offer-item-icon badge-dDay" v-if="dDay >= 0">{{dDay > 0 ? `D - ${dDay}` : '오늘'}}</div>
      </div>

      <div class="offer-item-eventType">{{$lib.t_genre(row.genre || row.categoryName)}}<span>|</span>{{row.eventType}}</div>

      <div class="offer-item-info">
        <div class="offer-item-info-date">
          <icon class="icon" src="img/common/calendar.png" width="2.5rem" height="2.5rem" :cover="false" />
          {{moment(row.playDate * 1000).format(time ? 'YYYY.MM.DD [/] a h[시] m[분]' : 'YYYY.MM.DD')}}
        </div>
        <div class="offer-item-info-addr">
          <icon class="icon" src="img/common/map-ico.png" width="1.8rem" height="2.5rem" :cover="false" />
          {{$lib.addrMinify(row.address)}}
        </div>
      </div>

      <template v-if="money">
        <div class="offer-item-money">
          {{ $lib.moneyRangeFormat(row) }}
        </div>
      </template>
    </div>

    <template v-if="footer">
      <!-- 일반회원일 때 -->
      <template v-if="!isArtist">
        <div class="ui-border-line ui-m-3 ui-mt-2 ui-mb-2"></div>

        <div
          v-if="row.mode === 'direct'"
          class="offer-item-recCount ui-btn direct"
        >
          <div @click="$router.push(`/artist/profile/${row.artistidx}`)">
            <p
              class="float-left ui-bg-img"
              :style="{
                backgroundImage: `url(${$lib.cdnUrl(row.teamImgUrl)})`,
              }"
            />
            <p class="float-left">{{row.teamName}}</p>
          </div>
          <p
            v-if="row.recCount > 0"
            class="float-right"
            @click="() => $router.push(`${myOffer ? '/client/my-offer' : '/offer'}/${row.offerUid}/${row.artistidx}`)"
          >
            견적보기
          </p>
        </div>
        <div
          v-else
          class="offer-item-recCount ui-btn"
          @click="() => $router.push(`${myOffer ? '/client/my-offer' : '/offer'}/${row.offerUid}/recruiter`)"
        >
          <p class="float-left">지원자</p>
          <p class="float-right">{{$lib.addComma(row.recCount)}}명</p>
        </div>
      </template>

      <!-- 전문가일 때 -->
      <template v-else-if="isArtist">
        <div
          class="offer-btn ui-btn"
          @click="gotoProfile(row)"
        >
          지원한 내용보기
        </div>
      </template>
    </template>

    <div class="ui-border-line ui-h-0 ui-mb-2"></div>
  </div>
</template>

<script>
import DaumMap from '@/components/common/DaumMap'
import Icon from '@/components/common/Icon'

export default {
  components: {
    DaumMap,
    Icon,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    map: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Boolean,
      default: false,
    },
    money: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    myOffer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userData: {
      get() {
        return this.$store.state.user.userData
      },
    },
    isClient: {
      get() {
        return this.userData.category === 'client'
      },
    },
    isArtist: {
      get() {
        return this.userData.category === 'artist'
      },
    },
    isRead: {
      get() {
        if (this.isClient) return false
        return this.row.proClientReadDate > 0
      },
    },
    isEnd: {
      get() {
        const now = Math.floor(Date.now() / 1000)
        const end = Number(this.row.playDate || 0)

        if (now >= end || ['ing', 'wait'].indexOf(this.row.nowState) === -1) return true

        return false
      },
    },
    isCancel() {
      if (this.row.mode === 'direct') {
        return !this.row.artistidx || this.row.artistidx == 0
      }
      return false
    },
    dDay: {
      get() {
        const now = moment(moment(new Date()).format('YYYY-MM-DD'))
        const end = moment(moment(Number(this.row.playDate) * 1000).format('YYYY-MM-DD'))
        return end.diff(now, 'days')
      },
    },
  },
  methods: {
    gotoOffer(row) {
      this.$router.push(`${this.myOffer ? `/${this.userData.category}/my-offer` : '/offer'}/${row.offerUid}`)
    },
    gotoProfile(row) {
      sessionStorage.setItem('offerData', JSON.stringify(row))
      this.$router.push(`${this.myOffer ? `/${this.userData.category}/my-offer` : '/offer'}/${row.offerUid}/${this.userData.useridx}`)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.offer-item {
  overflow: hidden;
  float: left;
  margin-top: 0.5rem;
  margin-right: 2rem;
  margin-bottom: 2.5rem;
  width: 32rem;
  border-radius: 0.7rem;
  box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;

  &:first-child {
    margin-left: 0.5rem;
  }

  // 사용 안함.
  .offer-item-img {
    width: 39rem;
    height: 19rem;
  }

  .offer-item-icon-box {
    margin: 2rem 2.5rem 0rem;
    min-height: 30px;

    .offer-item-icon {
      display: inline-block;
      margin-right: 0.6rem;
      width: auto;
      line-height: 2.3rem;
      height: 2.1rem;
      color: #fff;
      border-radius: 0.3rem;
      font-size: 1.1rem;
      font-weight: 500;
      text-align: center;
      padding: 0 0.6rem;

      &.badge-end {
        background-color: #9a9a9a;
      }
      &.badge-1-1 {
        background-color: #cacaca;
      }
      &.badge-read {
        background-color: #cacaca;
      }
      &.badge-new {
        background-color: #ff8f9f;
      }
      &.badge-dDay {
        background-color: #2bc4cc;
      }
    }
  }

  .offer-item-eventType {
    font-weight: 500;
    font-size: 1.9rem;
    margin: 0 2.5rem;
    span {
      color: #909090;
      margin: 0 1rem;
      font-size: 1.2rem;
      position: relative;
      top: -0.4rem;
    }
  }

  .offer-item-info {
    margin: 0 2.5rem 2.5rem;
    color: #909090;
    overflow: hidden;
    font-size: 1.8rem;
    > div {
      margin-top: 0.5rem;
    }

    .offer-item-info-date,
    .offer-item-info-addr {
      float: left;
    }

    .offer-item-info-date {
      width: 55%;
    }

    .icon {
      display: block;
      margin-right: 1.5rem;
      float: left;
    }
  }

  .offer-item-money {
    height: 2.55rem;
    font-size: 2.2rem;
    color: #a059b6;
    margin: 0 3rem;
  }

  .offer-item-recCount {
    // margin: 0 3rem 2rem;
    margin: -0.5rem 3rem 2.5rem;
    overflow: hidden;
    font-size: $font-small;
    color: #abaeb2;
    p {
      margin: 0;
      color: #000;

      &.float-right {
        color: #a059b6;
        font-weight: 500;
      }

    }
    &.direct {
      margin: -1.5rem 3rem 1.5rem;
      .ui-bg-img {
        margin-right: 1.4rem;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
      }
      p {
        line-height: 5rem;
      }
    }
  }

  .offer-btn {
    margin: 2.5rem 3rem 0;
    border: 1px solid #a059b6;
    border-radius: 0.6rem;
    text-align: center;
    color: #a059b6;
    font-size: 2rem;
    // line-height: 6.7rem;
    line-height: 6rem;
  }

  &.use-time {
    .offer-item-info {
      .offer-item-info-date,
      .offer-item-info-addr {
        float: none;
      }
      .offer-item-info-date {
        width: auto;
      }
      .icon {
        display: none;
      }
    }
  }
}
</style>
