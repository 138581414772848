import { defineStore } from 'pinia'
import { CACHE } from '@/pinia/keys'

export const useCacheStore = defineStore(CACHE, {
    state: () => ({
        cache: new Map(),
    }),
    actions: {
        getItem(key: string) {
            try {
                return JSON.parse(this.cache.get(key) || '') || null
            } catch {
                return null
            }
        },
        setItem(key: string, data) {
            this.cache.set(key, JSON.stringify(data))
        },
        removeItem(key: string) {
            this.cache.delete(key)
        },
        clear() {
            this.cache.clear()
        },
        /**
         * 홈 화면에서 실행할 메서드
         */
        clearForHomeScreen() {
            this.cache.delete('ClientMainMenuGroupArtistList')
        },
    },
})
