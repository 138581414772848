<template>
    <div
        class="hb-movie-thumbnail"
        :style="{
            backgroundImage,
        }"
        @click="() => open()"
    >
        <div class="movie-thumbnail--icon">
            <img :src="$lib.imgUrl('img/movie/play.png')" alt="play" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
        },
        height: {
            type: String,
        },
        movUrl: {
            type: String,
        },
        thumbnailUrl: {
            type: String,
        },
    },
    computed: {
        backgroundImage() {
            return this.thumbnailUrl ? `url(${this.thumbnailUrl})` : 'none'
        },
    },
    methods: {
        open() {
            if (/^https?:\/\/.+/.test(this.movUrl)) {
                this.$gotoWeb(this.movUrl)
                return
            }

            this.$toast('URL을 확인해주세요.')
        },
    },
}
</script>

<style lang="scss" scoped>
.hb-movie-thumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-color: #d9d9d9;
    cursor: pointer;

    .movie-thumbnail--icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        transform: translate(-50%, -50%);

        img {
            display: block;
            width: 100%;
        }
    }
}
</style>
