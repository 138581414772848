<template>
    <div
        class="a-mov-item"
        :class="{ 'show-player': isVideoReady }"
        :style="{ width: `${width || 0}px` }"
    >
        <div
            class="a-mov-item-thumbnail"
            :style="{
                height: `${height}px`,
            }"
            @click="() => openYoutube()"
        >
            <MovieThumbnail
                class="thumbnail"
                :thumbnailUrl="thumbnail"
                :movUrl="movUrl"
                :height="`${height}px`"
            />
        </div>
        <div class="a-mov-item-info" @click="() => gotoProfile()">
            <PuSkeleton class="a-mov-item-profile" :circle="true" :loading="imageLoading" width="35px" height="35px">
                <div
                    class="a-mov-item-profile"
                    :style="{
                        backgroundImage: teamImgUrl && `url(${$lib.cdnUrl(teamImgUrl)})` || null,
                    }"
                />
            </PuSkeleton>
            <div class="a-mov-item-text">
                <span>{{ teamName }}</span>
                <span class="mid">></span>
            </div>
            
        </div>
    </div>
</template>

<script>
import MovieThumbnail from '@/components/common/MovieThumbnail.vue'

export default {
    name: 'AMovItem',
    components: {
        MovieThumbnail,
    },
    props: {
        row: {
            required: true,
        },
        width: {
            type: Number,
            default: 231,
        },
    },
    data() {
        return {
            thumbnail: '',
            isVideoReady: false,
            imageLoading: false,
        }
    },
    computed: {
        height() {
            return this.width * 130 / 231
        },
        artistidx() {
            return this.row?.artistidx
        },
        createdDate() {
            return this.row?.createdDate
        },
        genre() {
            return this.row?.genre
        },
        listNum() {
            return this.row?.listNum
        },
        mainMovUid() {
            return this.row?.mainMovUid
        },
        movUrl() {
            return this.row?.movUrl
        },
        teamImgUrl() {
            return this.row?.teamImgUrl
        },
        teamName() {
            return this.row?.teamName
        },
        mainMenuArtistUid() {
            return this.row?.mainMenuArtistUid
        },
        youtubeId() {
            return this.$youtube.getIdFromUrl(this.movUrl)
        },
    },
    watch: {
        movUrl(movUrl) {
            this.setThumbnail(movUrl)
        },
    },
    mounted() {
        this.setThumbnail(this.movUrl)
        setTimeout(() => {
            this.loadBackgroundImages();    
        },500)
        
    },
    methods: {
        setThumbnail(movUrl) {
            if (movUrl && this.youtubeId) {
                this.thumbnail = `https://img.youtube.com/vi/${this.youtubeId}/mqdefault.jpg` || ''
            }
        },
        /**
         * @deprecated
         */
        onVideoReady() {
            this.isVideoReady = true
        },
        /**
         * @deprecated
         */
        onVideoError() {
            this.isVideoReady = false
        },
        gotoProfile() {
            if (this.artistidx) {
                this.$router.push({
                    path: `/artist/profile/${this.artistidx}`,
                    query: {
                        main_menu_artist_uid: this.mainMenuArtistUid,
                    },
                })
            }
        },
        /**
         * @deprecated
         */
        openYoutube() {
            // if (this.youtubeId) {
            //     useYoutubeModalStore().openById(this.youtubeId)
            // }
        },

        loadBackgroundImages() {
            this.imageLoading = true;

            const img = new Image();
            img.src = this.$lib.cdnUrl(this.thumbnail);

            img.onload = () => {
                this.imageLoading = false;
            };

            img.onerror = (e) => {
                this.imageLoading = false;
            }; 
        }
    },
}
</script>

<style lang="scss">
.a-mov-item {
    .a-mov-item-thumbnail {
        position: relative;

        .thumbnail {
            background-color: #d9d9d9;
            border-radius: 5px;
        }
    }

    .a-mov-item-info {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        .a-mov-item-profile {
            margin-right: 10px;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
        }
        .a-mov-item-text {
            flex: 1;
            display: flex;
            font-size: 1.8rem;
            font-weight: 600;
            color: #292929;
            align-items: center;
            span {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                &.mid {
                    margin: 0 5px;
                    color: #979797;
                    width: 17px;
                }
            }
        }
    }
    &.show-player {
        .a-mov-item-thumbnail {
            .thumbnail {
                display: none;
            }
        }
    }
}
</style>
